import React from 'react';
import "../../styles/Image1.css"

const Image1 = () => {
    return (
        <div className="image-layout">
            <div className="image-container">
                <img src="/assets/1.1.png" alt="Description of Image 1" className="image"/>
            </div>
            <div className="image-container">
                <img src="/assets/1.2.png" alt="Description of Image 1" className="image"/>
            </div>
            <div className="image-container">
                <img src="/assets/2.png" alt="Description of Image 2" className="image-2"/>
            </div>
        </div>
    );
};

export default Image1;
