import React from "react";
import "../../styles/Action.css";

const Action = () => {
    return (
            <div className="section-container-action">
                <div className="text-section-action">
                    <p>
                        Le CPMS vise <span className="highlight">la pleine reconnaissance de l’approche de médiation en santé dans la diversité de ses formes d’intervention</span>,
                        en cohérence avec {" "}
                        <a
                            href={`/assets/doc3.pdf`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="document-link"
                        >
                            le Référentiel de la HAS
                        </a>
                        , pour répondre aux besoins de santé des populations socialement éloignées du système de santé
                        et
                        favoriser leur recours aux services de santé.
                    </p>
                    <p>
                        Pour cela, les membres du Collectif mènent <span className="highlight">diverses actions, en plénière ou en groupe de travail</span> :
                    </p>
                </div>
                <div className="image-section">
                    <img
                        src="/assets/Picto-action.png" // Remplace cette URL par l'illustration réelle
                        alt="Illustration des actions du CPMS"
                        className="illustration"
                    />
                </div>
            </div>
    )
};

export default Action;
