import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import "../../styles/AccueilMembre.css"; // Créez un fichier CSS correspondant pour styliser ce composant

const AccueilMembre = () => {
    const [groupes, setGroupes] = useState([]);
    const [events, setEvents] = useState([]);
    const [searchGroupTerm, setSearchGroupTerm] = useState(''); // Recherche des groupes
    const [searchEventTerm, setSearchEventTerm] = useState(''); // Recherche des événements
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (token) {
            fetchGroupes();
            fetchEvents();
        } else {
            console.error("Token manquant, veuillez vous reconnecter.");
        }
    },  []);

    const fetchGroupes = async () => {
        try {
            const response = await axios.get('http://192.162.70.175:4000/groupes', {
                headers: { Authorization: `Bearer ${token}` },
            });
            const filteredGroupes = response.data.filter(
                (groupe) => groupe.name !== "COPIL" && groupe.name !== "Réunion Plénière"
            );
            setGroupes(filteredGroupes);
        } catch (error) {
            console.error("Erreur lors de la récupération des groupes de travail", error);
        }
    };

    const fetchEvents = async () => {
        try {
            const response = await axios.get('http://192.162.70.175:4000/events', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setEvents(response.data);
        } catch (error) {
            console.error("Erreur lors de la récupération des événements", error);
        }
    };

    const filteredGroupes = groupes.filter((groupe) =>
        groupe.name.toLowerCase().includes(searchGroupTerm.toLowerCase())
    );

    const filteredEvents = events.filter((event) =>
        event.title.toLowerCase().includes(searchEventTerm.toLowerCase())
    );

    return (
        <div className="accueil-membre">
            <header className="membre-header">
                <h1>Bienvenue dans votre Espace Membre</h1>
                <p>Accédez à toutes vos fonctionnalités et ressources en un seul endroit</p>
            </header>

            <div className="membre-content">
                <div className="membre-groupes">
                    <h3>Groupes de Travail</h3>
                    <div className="groupes-grid">
                        {filteredGroupes.length === 0 ? (
                            <p>Aucun groupe de travail disponible.</p>
                        ) : (
                            filteredGroupes.map((groupe) => (
                                <Link to={`/espace-membre/groupes/${groupe._id}`} key={groupe._id}
                                      className="groupe-link">
                                    <div className="groupe-card">
                                        <h4>{groupe.name}</h4>
                                    </div>
                                </Link>
                            ))
                        )}
                    </div>

                </div>

                <div className="membre-evenements">
                    <h3>Événements Récents</h3>
                    <div className="search-bar">
                        <input
                            type="text"
                            placeholder="Rechercher un événement..."
                            value={searchEventTerm}
                            onChange={(e) => setSearchEventTerm(e.target.value)}
                        />
                    </div>
                    <ul className="events-list">
                        {filteredEvents.length === 0 ? (
                            <p>Aucun événement prévu.</p>
                        ) : (
                            filteredEvents.map((event) => (
                                <li key={event._id} className="event-item">
                                    <h4>{event.title}</h4>
                                    <p>Date : {new Date(event.date).toDateString()}</p>
                                    <p>Heure de début : {event.startTime}</p>
                                    <p>Heure de fin : {event.endTime}</p>
                                    <p>Groupe : {event.groupId?.name || "Non spécifié"}</p>
                                </li>
                            ))
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default AccueilMembre;
