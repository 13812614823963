import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HeaderIntranet from './EspaceMembre/HeaderIntranet';
import Agenda from './EspaceMembre/Agenda';
import Accueil from './EspaceMembre/AcceuilMembre';
import Groupe from './EspaceMembre/Groupe';
import GroupeDetails from "./EspaceMembre/GroupeDetails";
import '../styles/EspaceMembre.css';
import Documents from "./EspaceMembre/Documents";
import Admin from "./EspaceMembre/Admin";

const EspaceMembre = () => {
    return (
        <div className="espace-membre">
            <HeaderIntranet />
            <div className="intranet-content">
                <Routes>
                    <Route path="/" element={<Accueil />}/>
                    <Route path="agenda" element={<Agenda />} />
                    <Route path="documents" element={<Documents />} />
                    <Route path="groupes" element={<Groupe />} />
                    <Route path="groupes/:id" element={<GroupeDetails />} />
                    <Route path="admin" element={<Admin />} />
                </Routes>
            </div>
        </div>
    );
};

export default EspaceMembre;
