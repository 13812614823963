import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Login from './components/Login';
import SignUp from './components/SignUp';
import EspaceMembre from './components/EspaceMembre';
import PrivateRoute from './components/PrivateRoute';
import ResetPassword from "./components/ResetPassword";
import ForgotPassword from "./components/ForgotPassword";
import Ressources from "./components/SiteVitrine/Ressource";
import SubResources from "./components/SiteVitrine/SubResources";
import Accueil from "./components/SiteVitrine/Accueil";
import QuiSommeNous from "./components/SiteVitrine/QuiSommeNous";
import Organisation from "./components/SiteVitrine/Organisation";
import Mediation from "./components/SiteVitrine/Mediation";
import MediationAdditional from "./components/SiteVitrine/MediationAdditional";
import MediationVideo from "./components/SiteVitrine/MediationVideo";
import Image1 from "./components/SiteVitrine/Image1";
import Image2 from "./components/SiteVitrine/Image2";
import Image3 from "./components/SiteVitrine/Image3";
import Image4 from "./components/SiteVitrine/Image4";
import Actions from "./components/SiteVitrine/Actions";
import Demande from "./components/SiteVitrine/Demande";
import Contact from "./components/SiteVitrine/Contact";
import './App.css';


function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));

    return (
        <Router>
            <Header isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
            <div className="main-content">
                <Routes>
                    <Route path="/" element={<Accueil />} />
                    <Route path="/qui-sommes-nous" element={<QuiSommeNous />} />
                    <Route path="/qui-sommes-nous/demandes" element={<Demande />} />
                    <Route path="/qui-sommes-nous/membres" element={<Organisation />} />
                    <Route path="/qui-sommes-nous/actions" element={<Actions />} />
                    <Route path="/mediation-en-sante/definition" element={<Mediation/>} />
                    <Route path="/mediation-en-sante/metier-mediateur" element={<MediationAdditional/>} />
                    <Route path="/mediation-en-sante/formations" element={<div>Formations</div>} />
                    <Route path="/mediation-en-sante/temoignages" element={<div>Témoignages</div>} />
                    <Route path="/mediation-en-sante/ressources" element={<Ressources />} />
                    <Route path="/mediation-en-sante/ressources/:category" element={<SubResources />} />
                    <Route path="/preconisation" element={<MediationVideo />} />
                    <Route path="/preconisation/referentiel" element={<Image1 />} />
                    <Route path="/preconisation/formations" element={<Image2 />} />
                    <Route path="/preconisation/accompagnement" element={<Image3 />} />
                    <Route path="/preconisation/deploiment" element={<Image4 />} />
                    <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/nous-rejoindre" element={<SignUp />} />
                    <Route path="/reset-password/:token" element={<ResetPassword />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route
                        path="/espace-membre/*"
                        element={
                            <PrivateRoute>
                                <EspaceMembre />
                            </PrivateRoute>
                        }
                    />
                </Routes>
            </div>
            <Footer />
        </Router>
    );
}

export default App;
