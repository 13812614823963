import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import "../../styles/HeaderIntranet.css";

const HeaderIntranet = () => {
    const [groupes, setGroupes] = useState([]);
    const [userRole, setUserRole] = useState('');
    const [copilId, setCopilId] = useState(null);
    const [pleniereId, setPleniereId] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    return;
                }

                const groupesResponse = await axios.get('http://192.162.70.175:4000/groupes', {
                    headers: { Authorization: `Bearer ${token}` }
                });

                const allGroupes = groupesResponse.data;
                const copil = allGroupes.find((groupe) => groupe.name === "COPIL");
                const pleniere = allGroupes.find((groupe) => groupe.name === "Réunion Plénière");

                if (copil) setCopilId(copil._id);
                if (pleniere) setPleniereId(pleniere._id);

                const filteredGroupes = allGroupes.filter(
                    (groupe) => groupe.name !== "COPIL" && groupe.name !== "Réunion Plénière"
                );

                setGroupes(filteredGroupes);

                const userResponse = await axios.get('http://192.162.70.175:4000/users/me', {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setUserRole(userResponse.data.role); // Assurez-vous que votre backend renvoie le rôle de l'utilisateur

            } catch (error) {
                console.error('Erreur lors de la récupération des données', error);
            }
        };

        fetchUserData();
    }, []);

    return (
        <header className="header-intranet">
            <nav>
                <ul className="intranet-menu">
                    <li className="intranet-menu-item">
                        <Link to="/espace-membre/">Accueil</Link>
                    </li>

                    {userRole === 'admin' && (
                        <>
                            <li className="intranet-menu-item">
                                <Link to="/espace-membre/agenda">Agenda</Link>
                            </li>
                            <li className="intranet-menu-item">
                                <Link to="/espace-membre/documents">Déposer des Documents</Link>
                            </li>
                            <li className="intranet-menu-item">
                                <Link to="/espace-membre/admin">Panels Administrateur</Link>
                            </li>
                        </>
                    )}

                    <li className="intranet-menu-item">
                        {userRole === 'admin' ? (
                            <Link to="/espace-membre/groupes">Groupes de Travail</Link>
                        ) : (
                            <span className="non-clickable">Groupes de Travail</span>
                        )}
                        <ul className="dropdown-menu">
                            {groupes.length === 0 ? (
                                <li>Aucun groupe disponible</li>
                            ) : (
                                groupes.map((groupe) => (
                                    <li key={groupe._id}>
                                        <Link to={`/espace-membre/groupes/${groupe._id}`}>{groupe.name}</Link>
                                    </li>
                                ))
                            )}
                        </ul>
                    </li>

                    {copilId && (
                        <li className="intranet-menu-item">
                            <Link to={`/espace-membre/groupes/${copilId}`}>COPIL</Link>
                        </li>
                    )}
                    {pleniereId && (
                        <li className="intranet-menu-item">
                            <Link to={`/espace-membre/groupes/${pleniereId}`}>Réunion Plénière</Link>
                        </li>
                    )}
                </ul>
            </nav>
        </header>
    );
};

export default HeaderIntranet;
