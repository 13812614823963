import React from "react";
import "../../styles/Demande.css";
import {Link} from "react-router-dom";

const Demande = () => {
    return (
        <div className="section-container">
            {/* Texte à gauche */}
            <div className="text-section-demandes">
                <div className="actions-box">
                    <p>
                        Les membres du Collectif pour la promotion de la médiation en santé demandent aux pouvoirs
                        publics français d’engager des actions concrètes et des moyens financiers en vue de reconnaitre
                        et sécuriser le métier de médiatrice/médiateur en santé, en :
                    </p>
                    <ul>
                        <li>
                            <Link to="/preconisation/referentiel" className="hyperlink">
                                Créant un référentiel métier de médiatrice/médiateur en santé avec une place
                                opérationnelle définie dans les systèmes de santé.
                            </Link>
                        </li>
                        <li>
                            <Link to="/preconisation/formations" className="hyperlink">
                                Mettant en place des formations en médiation en santé qualifiantes initiales et
                                continues reconnues par l’État.
                            </Link>
                        </li>
                        <li>
                            <Link to="/preconisation/accompagnement" className="hyperlink">
                                Garantissant un soutien et un accompagnement professionnel aux médiatrices et médiateurs
                                en santé.
                            </Link>
                        </li>
                        <li>
                            <Link to="/preconisation/deploiment" className="hyperlink">
                                Finançant et encourageant le déploiement de postes de médiatrices et médiateurs en santé
                                à la hauteur des besoins.
                            </Link>
                        </li>
                    </ul>
                    <p>
                        Cela dans le cadre d’une stratégie nationale de déploiement et de pérennisation de la médiation
                        en santé, assortie de modalités de financements dédiés et d’un comité de suivi et d’évaluation.
                    </p>
                </div>
                <a
                    href={`/assets/doc2.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="rapport-button"
                >
                    Voir doc de plaidoyer 2.0
                </a>
            </div>

            {/* Image à droite */}
            <div className="image-section">
                <img
                    src="/assets/logo-objectif.png" // Remplace par le chemin réel de l'image
                    alt="Réunion du collectif"
                    className="advocacy-image"
                />
            </div>
        </div>
    );
};

export default Demande;
