import React from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/QuiSommeNous.css";

const Homepage = () => {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <div className="homepage-container">
            {/* Section de texte en haut */}
            <div className="header-section">
                <p>
                    <span className="highlight">Premier collectif national de la médiation en santé</span>,  le CPMS s'est formé en 2022 pour « faire force commune» afin de promouvoir et sécuriser le métier de médiateur.rice en santé, dans un cadre éthique cohérent et selon des référentiels communs reconnaissant la richesse et l'agilité de ses contextes d'intervention.
                </p>
            </div>

            {/* Section des icônes en bas */}
            <div className="icons-section">
                <div
                    className="icon-box"
                    onClick={() => handleNavigation("/qui-sommes-nous/membres")}
                >
                    <img
                        src="/assets/1C.png" // Remplace par l'URL de ton icône "Ses membres"
                        alt="Ses membres"
                        className="icon-special"
                    />
                    <h3>Ses membres</h3>
                </div>

                <div
                    className="icon-box"
                    onClick={() => handleNavigation("/qui-sommes-nous/actions")}
                >
                    <img
                        src="/assets/2C.png" // Remplace par l'URL de ton icône "Ses actions"
                        alt="Ses actions"
                        className="icon"
                    />
                    <h3>Ses actions</h3>
                </div>

                <div
                    className="icon-box"
                    onClick={() => handleNavigation("/qui-sommes-nous/demandes")}
                >
                    <img
                        src="/assets/3C.png" // Remplace par l'URL de ton icône "Ses demandes"
                        alt="Ses demandes"
                        className="icon"
                    />
                    <h3>Ses demandes</h3>
                </div>
            </div>
        </div>
    );
};

export default Homepage;
