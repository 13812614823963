import '../../styles/MediationVideo.css';
import React from "react";

const MediationVideo = () => {
    return (
        <div className="mediation-video-container">
            <div className="left-section">
                <a
                    href="https://youtu.be/tZzG0fNXd_c?si=uPt8K7174UIEgQjK"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        src="/assets/youtube.webp"
                        alt="Study Details"
                        className="picto"
                        style={{ cursor: 'pointer' }}
                    />
                </a>
                <div className="button-container">
                    <a
                        href="/assets/doc4.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="download-button"
                    >
                        Rapport étude C-PRO-MS ici
                    </a>
                </div>
            </div>

            <div className="right-section-video">
                <div className="text-container">
                    <h2>Contexte</h2>
                    <p>
                        Malgré l'existence d'un cadre légal de la médiation en santé - celle-ci est inscrite dans la loi
                        de
                        modernisation de notre système de santé du 26 janvier 2016 et dans le Code de la Santé Publique
                        –, le
                        métier de médiateur⸱rices en santé ne bénéficie pas d'un statut professionnel reconnu.
                    </p>
                    <p>
                        L’absence d'identification de ce métier en termes de référentiel que ce soit au niveau du
                        recensement
                        dans le Répertoire Opérationnel des Métiers et des Emplois (ROME), de la certification
                        professionnelle,
                        du positionnement dans les conventions collectives (grille de salaires, évolution de carrière,
                        etc.) ou
                        de la formation qualifiante, impacte lourdement les conditions de travail des médiatrices et
                        médiateurs
                        en santé et la mise en œuvre au quotidien de leurs actions.
                    </p>
                    <p>
                        Selon les constats empiriques, ces difficultés seraient également liées à la non-reconnaissance
                        de leur
                        positionnement et de leur légitimité dans les collaborations avec les professionnels des
                        secteurs
                        sanitaire, social et du médico-social.
                    </p>
                    <p>
                        Co-pilotée par la Fnasat-GV et Sidaction, l'étude visait à élaborer un état des lieux du cadre
                        professionnel des médiatrices et médiateurs en santé relatif à leurs conditions d’emploi et
                        d’exercice,
                        en 2023, dans toute la France.
                    </p>
                    <p>
                        Cette étude s'inscrit dans la démarche de plaidoyer pour la reconnaissance
                        et la sécurisation professionnelle du métier, portée par le Collectif pour la Promotion de la
                        médiation
                        en santé (CPMS).
                    </p>
                    <h2>Méthodes</h2>
                    <p>
                        Nous avons mené une étude en méthodes mixtes, en France, de janvier à juin 2023, auprès de
                        médiateur⸱rices
                        en santé et de directions ou de coordinations de structures employant des médiateur⸱rices en
                        santé.
                        L’étude comportait une enquête transversale quantitative, complétée dans un second temps par une
                        enquête
                        qualitative. L’étude a été réalisée de manière collaborative et participative.
                    </p>
                    <p>
                        Concernant l’enquête quantitative, la collecte des données a été réalisée à l’aide de deux auto-
                        questionnaires en ligne, renseignant le cadre professionnel des médiateur⸱rices en santé, l’un à
                        destination des médiateur⸱rices en santé et l’autre à destination des directions ou des
                        coordinations de
                        structures employant des médiateur⸱rices en santé à l’aide d’un échantillonnage en boule de
                        neige. Pour
                        l’analyse, nous avons décrit les données pour faire l’état des lieux du cadre professionnel des
                        médiateur⸱rices en santé. Nous l’avons complété par : 1/ une analyse univariée pour spécifier le
                        cadre
                        professionnel en fonction du statut ou non de pair et de la catégorie de la structure
                        employeuse, et 2/
                        une régression logistique pour identifier les facteurs associés à la reconnaissance du métier de
                        médiatrice et médiateur en santé par les partenaires.
                    </p>
                    <p>
                        Concernant l’enquête qualitative, nous avons réalisé des focus groupes auprès des
                        médiateur⸱rices en
                        santé participant aux activités du CPMS, à l’aide d’un échantillonnage de convenance. Nous avons
                        mené un
                        focus group pour approfondir notre compréhension des résultats quantitatifs autour de la
                        reconnaissance
                        du métier de médiateur et médiatrice en santé, et cinq focus groupes pour cointerpréter les
                        résultats
                        quantitatifs et identifier les propositions concrètes à mettre en œuvre pour remédier aux
                        difficultés
                        perçues. Nous avons conduit ces focus groupes à l’aide de guides préalablement établis puis
                        réalisé une
                        analyse de contenu thématique. Nous avons à la suite triangulé les données en vue de formaliser
                        des
                        recommandations concrètes.
                    </p>

                    <h2>Résultats</h2>
                    <p>
                        Au total 215 médiateur⸱rices en santé exerçant dans 151 structures mettant en œuvre la médiation
                        en santé
                        sur le territoire français ont participé à l’enquête quantitative.
                    </p>
                    <p>
                        Le profil des participant⸱es se
                        caractérisait par une majorité féminine (69%). Plus de 69% des participant⸱es avaient un niveau
                        d’étude
                        inférieur ou égal à bac+3. Le niveau d’étude le plus représenté était le niveau d’étude Bac + 3
                        (34%).
                        Presque tous les participant⸱es (98%) avaient une formation initiale. Parmi les participant⸱es,
                        30%
                        avaient un diplôme en médiation en santé et 20% étaient en formation en médiation en santé.
                    </p>
                    <p>
                        Les principaux statuts juridiques des structures employant des médiateur⸱rices en santé étaient
                        des
                        associations (67%), des établissements publics de santé (19%). La moitié des structures
                        percevaient de
                        multiples financements pour un poste de médiateur⸱rice en santé d’une durée médiane d’un an. Un
                        peu moins
                        de la moitié des structures avait des grilles de salaires pour les médiateur⸱rices en santé et
                        un quart
                        des structures n’avait pas de convention collective. La médiane du nombre de poste de
                        médiateur⸱rice en
                        santé était de un. Plus d’un tiers des structures se déclarait en sous-effectif de
                        médiateur⸱rices en
                        santé.
                    </p>
                    <p>
                        L’ancienneté des médiateur⸱rices en santé au sein de leur structure était en médiane de 2 ans.
                        Un tiers
                        des participant⸱es était en contrat à durée déterminée et un tiers travaillait à temps partiel.
                        Le salaire
                        net mensuel médian était de 1 620 euros IIQ [1 371, 1 900] avec un taux horaire net médian de
                        11,10 euros
                        [9,66, 12,69]. Une grande partie des participant⸱es percevait leur situation financière comme
                        impossible
                        (6.5%), difficile (17%) ou juste (37%).
                    </p>
                    <p>
                        La moitié des médiateur⸱rices en santé exprimait un manque de moyens matériels et financiers
                        pour
                        l’exercice de leurs missions. Plus des trois quarts des médiateur⸱rices en santé exprimaient des
                        difficultés dans leur collaboration avec les partenaires avec lesquels ils⸱elles sont amené⸱es à
                        travailler dans leurs missions. Un tiers des médiateur⸱rices en santé ressentait un manque de
                        reconnaissance de la part des partenaires (e.g., professionnels de santé et du travail social,
                        services
                        déconcentrés de l’État tels que les mairies, préfectures etc. et avec des élus).
                    </p>
                    <p>
                        Les facteurs associés à une meilleure reconnaissance des médiateur⸱rices en santé auprès de
                        leurs
                        partenaires étaient le fait d’avoir une situation financière perçue comme stable (p=0.01), un
                        soutien
                        de l’encadrement de leur structure d’exercice (p=0.01), l’accès à des analyses de pratique
                        (p=0.05),
                        un sentiment de reconnaissance auprès des publics accompagnés (p=0.01), le sentiment de
                        reconnaissance
                        de l’utilité de la médiation en santé (p=0.02), et la reconnaissance de la place de tiers par
                        les partenaires (p=0.001).
                    </p>
                    <p>
                        L’enquête qualitative a précisé que malgré le fait que l’efficacité du champ d’expertise du
                        médiateur⸱rice
                        en santé soit bien reconnue par les travailleur·euses sociaux⸱ales et les professionnel⸱les de
                        santé,
                        les activités, enjeux, et limites du métier apparaissaient mal connus des partenaires. Tou⸱tes
                        les participant⸱es
                        ont partagé un sentiment de non-existence officielle du métier de médiateur⸱rice en santé et un
                        défaut d’identité sociale
                        de la médiation en santé dans les secteurs social et médico-social et de manière accrue dans le
                        secteur sanitaire.
                    </p>
                    <p>
                        Les participant⸱es des six focus groupes ont formulé des propositions visant la sécurisation du
                        métier de médiateur⸱rice en santé. Ces propositions concernaient :
                    </p>
                    <ul>
                        <li>
                            Le statut professionnel, avec en particulier l’inscription souhaitée du métier dans le Code
                            de la santé publique et le Répertoire Opérationnel des Métiers et des Emplois (ROME), son
                            positionnement dans les conventions collectives des secteurs sanitaire, social et
                            médicosocial et de la fonction publique hospitalière et territoriale, l’adoption d’une
                            charte ou d’un cadre éthique ayant une valeur réglementaire et la formalisation d’une place
                            opérationnelle, pour les médiateur⸱rices en santé, dans le système de santé.
                        </li>
                        <li>
                            La formation, avec la mise en place de formations initiales et continues qualifiantes de
                            médiateur⸱rice en santé accessibles sans exigence de diplôme préalable à l’entrée et la
                            création d’une certification professionnelle permettant de valoriser les compétences
                            acquises par l’expérience.
                        </li>
                        <li>
                            Le soutien et l’accompagnement professionnel des médiateur⸱rices en santé, avec la mise en
                            place d’un appui à trois niveaux: face aux difficultés rencontrées dans les missions
                            (analyse et échange de pratiques entre médiateur⸱rices en santé), un soutien personnel dans
                            l’exercice du métier (appui psychologique), un accompagnement des structures employeuses à
                            l’intégration des médiateur⸱rices en santé dans leurs équipes et des partenaires mobilisant
                            la médiation en santé, cet axe de demande étant formalisé dans la mise en place souhaitée,
                            en lien avec les Agences régionales de santé (ARS), de programmes régionaux de médiation en
                            santé (PRMS) pour et avec des médiateur⸱rices en santé.
                        </li>
                        <li>
                            Le financement pérenne des projets et postes de médiation en santé par des canaux stables,
                            lisibles et adaptés permettant un déploiement adapté aux besoins des territoires.
                        </li>
                    </ul>
                    <h2>Discussion</h2>
                    <p>
                        Cette étude met en lumière les difficultés rencontrées par les médiateur⸱rices en santé dans
                        l’exercice
                        de leur métier, en lien avec un manque de sécurisation professionnelle. Alors que les
                        acteur⸱rices de
                        terrain et de la recherche sont déjà pleinement engagé⸱es en faveur de la reconnaissance et
                        sécurisation
                        du métier, notamment via le plaidoyer porté par le CPMS, l’évolution du métier nécessite
                        l’implication
                        significative et durable des pouvoirs publics aux différentes échelles territoriales concernées,
                        dans
                        cette démarche collaborative.
                    </p>
                    <p>
                        Une voie pour aller vers les changements attendus pourrait être la démarche d’une stratégie
                        nationale de
                        déploiement et de pérennisation de la médiation en santé, assortie de financements dédiés à sa
                        mise en
                        œuvre, son suivi et son évaluation.
                    </p>
                    <p>
                        Une autre voie qui apparaît pertinente et ancrée dans l’expérience et l’histoire des
                        collaborations
                        acteur⸱rices, décideur⸱ses, chercheur⸱ses sur les territoires, consisterait à s’appuyer et à
                        accompagner
                        les initiatives régionales de structuration de la médiation en santé. En effet, des démarches de
                        mobilisation d’acteur⸱rices et d’animation de réseaux, voire de préfiguration de programmes
                        régionaux de
                        médiation en santé en lien avec une communauté de pratiques autour des besoins en santé des
                        populations
                        en situation de vulnérabilité sociale, se font jour sur plusieurs territoires, à des échelles de
                        déploiement et à des niveaux d’avancement variables (e.g., Guyane, Nouvelle-Aquitaine, Hauts de
                        France,
                        Auvergne Rhône Alpes, Pays de la Loire, Provence Alpes Côte d’Azur). Aller vers une
                        mutualisation de ces
                        expériences et une capitalisation des bonnes pratiques pour favoriser une implémentation
                    </p>
                    <p>
                        L’engagement politique apparaît ainsi comme un enjeu décisif pour inscrire ces évolutions dans
                        un cadre légal et/ou règlementaire et atteindre une sécurisation des pratiques dans un cadre
                        professionnel éthique cohérent, fondé sur un socle commun de référence reconnaissant et
                        valorisant. Néanmoins, la diversité des profils des médiateur⸱rices en santé, pairs et non
                        pairs, les expériences et les contextes d’intervention, nécessaires à l’adaptation constante de
                        la médiation en santé à un contexte socialement mouvant. Cette sécurisation devra donc advenir
                        avec le souci de ne pas rigidifier les pratiques.
                    </p>
                    <p className='bold'>
                        Quelle que soit la voie qui sera privilégiée, il y a urgence à sécuriser ce métier, pour
                        garantir l’efficacité de la médiation en santé, un accès équitable et un recours effectif au
                        système de santé pour toutes et tous.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default MediationVideo;
