import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import "../../styles/Ressource.css"

const Ressources = () => {
    const [ressources, setRessources] = useState({ articles: [], rapports: [], medias: [] });
    const [selectedFile, setSelectedFile] = useState(null);
    const [category, setCategory] = useState('');
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        if (token) {
            fetchRessources();
            checkAdminStatus();
        } else {
            console.error('Token manquant, veuillez vous reconnecter.');
        }
    }, [token]);

    const fetchRessources = async () => {
        try {
            const response = await axios.get('http://192.162.70.175:4000/ressources', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setRessources(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des ressources', error);
        }
    };

    const checkAdminStatus = async () => {
        try {
            const response = await axios.get('http://192.162.70.175:4000/admin-status', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setIsAdmin(response.data.isAdmin);
        } catch (error) {
            console.error('Erreur lors de la vérification du statut administrateur', error);
        }
    };

    const handleFileUpload = async (e) => {
        e.preventDefault();
        if (!selectedFile || !category) return;

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('category', category);

        try {
            const response = await axios.post('http://192.162.70.175:4000/upload-ressource', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            });
            setRessources(prev => ({
                ...prev,
                [category]: [...prev[category], response.data]
            }));
            setSelectedFile(null);
            setCategory('');
        } catch (error) {
            console.error('Erreur lors du téléchargement du document', error);
        }
    };

    const handleCategoryClick = (category) => {
        navigate(`/mediation-en-sante/ressources/${category}`);
    };

    return (
        <div className="ressources-section">
            <div className="ressources-categories">
                <div className="category-box" onClick={() => handleCategoryClick('rapports')}>
                    <h3>RAPPORTS</h3>
                </div>
                <div className="category-box" onClick={() => handleCategoryClick('articles')}>
                    <h3>Articles et études scientifiques</h3>
                </div>
                <div className="category-box" onClick={() => handleCategoryClick('medias')}>
                    <h3>MEDIAS</h3>
                </div>
            </div>
            {isAdmin && (
                <div className="upload-section">
                    <h2>Dépôt de Ressources</h2>
                    <form onSubmit={handleFileUpload} className="upload-form">
                        <input type="file" onChange={(e) => setSelectedFile(e.target.files[0])} required />
                        <div className="form-group">
                            <label htmlFor="category">Catégorie :</label>
                            <select
                                id="category"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                                required
                            >
                                <option value="">-- Sélectionnez une catégorie --</option>
                                <option value="articles">Articles</option>
                                <option value="rapports">Rapports</option>
                                <option value="medias">Médias</option>
                            </select>
                        </div>
                        <button type="submit">Déposer</button>
                    </form>
                </div>
            )}
        </div>
    );
};

export default Ressources;