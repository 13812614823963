import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/Groupe.css';

const Groupe = () => {
    const [groupes, setGroupes] = useState([]);
    const [newGroupName, setNewGroupName] = useState('');
    const [newGroupDescription, setNewGroupDescription] = useState('');
    const [message, setMessage] = useState('');
    const [editingGroup, setEditingGroup] = useState(null);
    const [editedGroupName, setEditedGroupName] = useState('');
    const [editedGroupDescription, setEditedGroupDescription] = useState('');

    useEffect(() => {
        fetchGroupes();
    }, []);

    const fetchGroupes = async () => {
        try {
            const token = localStorage.getItem('token'); // Récupérer le token JWT depuis localStorage
            const response = await axios.get('http://192.162.70.175:4000/groupes', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setGroupes(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des groupes', error);
        }
    };

    const handleCreateGroup = async (e) => {
        e.preventDefault();
        if (!newGroupName) {
            setMessage('Veuillez entrer un nom de groupe.');
            return;
        }

        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(
                'http://192.162.70.175:4000/groupes',
                { name: newGroupName, description: newGroupDescription },
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            setGroupes([...groupes, response.data]);
            setNewGroupName('');
            setNewGroupDescription('');
            setMessage('Groupe créé avec succès !');
        } catch (error) {
            console.error('Erreur lors de la création du groupe', error);
            setMessage('Erreur lors de la création du groupe');
        }
    };

    const handleDeleteGroup = async (groupId) => {
        try {
            const token = localStorage.getItem('token');
            await axios.delete(
                `http://192.162.70.175:4000/groupes/${groupId}`,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            fetchGroupes();

            setMessage('Groupe supprimé avec succès.');
        } catch (error) {
            console.error('Erreur lors de la suppression du groupe', error);
            setMessage('Erreur lors de la suppression du groupe');
        }
    };

    const handleEditGroup = (groupe) => {
        setEditingGroup(groupe._id);
        setEditedGroupName(groupe.name);
        setEditedGroupDescription(groupe.description);
    };

    const handleSaveEdit = async (groupId) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.put(
                `http://192.162.70.175:4000/groupes/${groupId}`,
                { name: editedGroupName, description: editedGroupDescription },
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setGroupes(groupes.map((groupe) => (groupe._id === groupId ? response.data : groupe)));
            setEditingGroup(null);
            setMessage('Groupe modifié avec succès.');
        } catch (error) {
            console.error('Erreur lors de la modification du groupe', error);
            setMessage('Erreur lors de la modification du groupe');
        }
    };

    return (
        <div className="groupe-wrapper">
            <h2>Groupes de Travail</h2>
            <form onSubmit={handleCreateGroup} className="groupe-form">
                <input
                    type="text"
                    placeholder="Nom du nouveau groupe"
                    value={newGroupName}
                    onChange={(e) => setNewGroupName(e.target.value)}
                />
                <textarea
                    placeholder="Description du nouveau groupe"
                    value={newGroupDescription}
                    onChange={(e) => setNewGroupDescription(e.target.value)}
                />
                <button type="submit">Créer un groupe</button>
            </form>
            <p>{message}</p>

            <div className="groupes-list">
                <h3>Groupes existants</h3>
                {groupes.length === 0 ? (
                    <p>Aucun groupe n'est disponible pour le moment.</p>
                ) : (
                    groupes.map((groupe) => (
                        <div key={groupe._id} className="groupe-item">
                            {editingGroup === groupe._id ? (
                                <div className="edit-group-form">
                                    <input
                                        type="text"
                                        value={editedGroupName}
                                        onChange={(e) => setEditedGroupName(e.target.value)}
                                    />
                                    <textarea
                                        value={editedGroupDescription}
                                        onChange={(e) => setEditedGroupDescription(e.target.value)}
                                    />
                                    <button onClick={() => handleSaveEdit(groupe._id)}>Sauvegarder</button>
                                </div>
                            ) : (
                                <>
                                    <h4>{groupe.name}</h4>
                                    <p>{groupe.description}</p>
                                    <button onClick={() => handleEditGroup(groupe)}>Modifier</button>
                                    <button onClick={() => handleDeleteGroup(groupe._id)}>Supprimer</button>
                                </>
                            )}
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default Groupe;
