import React, { useState } from 'react';
import axios from 'axios';
import '../styles/ForgotPassword.css';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('http://192.162.70.175:4000/forgot-password', { email });
            setMessage('Un email contenant le lien de réinitialisation de votre mot de passe a été envoyé.');
        } catch (error) {
            setMessage("Erreur lors de l'envoi de l'email de récupération.");
            console.error(error);
        }
    };

    return (
        <div className="forgot-password-page">
            <div className="forgot-password-form-container">
                <h2>Réinitialiser le mot de passe</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>Email</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit">Envoyer</button>
                    {message && <p className="message">{message}</p>}
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;
