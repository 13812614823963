import React from 'react';
import '../../styles/Mediation.css';

const Mediation = () => {
    return (
        <div className="mediation-container">
            <div className="left-images-section">
                <img src="/assets/mediation2.png" alt="Discussion" className="mediation-image" />
                <img src="/assets/mediation3.png" alt="Meeting" className="mediation-image" />
            </div>
            <div className="right-text-section">
                <p className="mediation-text">
                    La médiation en santé est aujourd'hui reconnue comme un mode d'intervention efficace dans la lutte contre les inégalités sociales de santé.
                </p>
                <p className="mediation-text">
                    L'inscription de la médiation en santé dans la loi de modernisation de notre système de santé du 26 janvier 2016 et dans le Code de la santé publique (décret n°2017-816) n'a pourtant pas été suivie par la mise en place d'un cadre statutaire garantissant la reconnaissance et la sécurisation de ce métier.
                </p>
                <p className="mediation-text-blue">
                    La médiation en santé désigne une fonction d'interface assurée dans la proximité entre les personnes en situation de vulnérabilités, éloignées du système de santé, et les professionnel·les intervenant dans leurs parcours de santé.
                </p>
                <h2>Ses objectifs</h2>
                <p className="mediation-text">
                    La médiation en santé a pour but d’articuler des actions visant :
                </p>
                <ul className="mediation-objectives">
                    <li>d’une part, à faciliter l’accès aux droits, à la prévention et aux soins des personnes exposées à une ou plusieurs vulnérabilités et,
                    </li>
                    <li>d’autre part, à sensibiliser les professionel·les concerné·es par leurs besoins et problématiques sur les obstacles rencontrés.
                    </li>
                </ul>
                <p className="mediation-text">
                    La médiation en santé s'inscrit dans une approche globale et intégrée de la santé en référence aux valeurs de promotion de la santé, de l'approche communautaire, et de l'éducation par les pairs.
                </p>
                <p className="mediation-text">
                    L'accompagnement qu’elle propose vise à permettre aux personnes et aux populations de surmonter les obstacles individuels et/ou systémiques qu’elles rencontrent pour recourir aux services de santé, développer la perception de leurs besoins en santé et ainsi renforcer leurs capacités pour favoriser leur autonomie en santé.
                </p>
                <h2>Son impact</h2>
                <p className="mediation-text">
                    La médiation en santé soutient la transformation du système de santé dans une visée d'équité en santé. Elle révèle les besoins non couverts et les défaillances d'un système mal adapté à toutes et tous. Elle porte à la connaissance des institutions une meilleure prise en compte et une adaptation des dispositifs.
                </p>
                <p className="mediation-text">
                    Elle prend en compte les déterminants sociaux de la santé (droits, logement, emploi, éducation). Elle concoure à l'amélioration de l'accès au système de santé et à la lutte contre les discriminations et l'exclusion.
                </p>
            </div>
        </div>
    );
};

export default Mediation;
