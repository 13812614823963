import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Importer Axios pour gérer les requêtes HTTP
import '../styles/Login.css'; // Importer le CSS

function Login({ setIsLoggedIn }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = (e) => {
        e.preventDefault();

        const userData = {
            email: username,
            password
        };

        axios.post('http://192.162.70.175:4000/login', userData)
            .then((response) => {
                const { token } = response.data;
                localStorage.setItem('token', token);
                setIsLoggedIn(true);
                navigate('/espace-membre/');
            })
            .catch((error) => {
                setError('Identifiants incorrects');
                console.error(error);
            });
    };

    const handleForgotPassword = () => {
        navigate('/forgot-password');
    };

    return (
        <div className="login-page">
            <h2>Connexion à l'espace membre</h2>
            <div className="login-form-container">
                <form onSubmit={handleLogin}>
                    <div>
                        <label>Email</label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Mot de passe</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    <button type="submit">Se connecter</button>
                </form>
                <button className="forgot-password-button" onClick={handleForgotPassword}>Mot de passe oublié ?</button>
            </div>
        </div>
    );
}

export default Login;
