import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../styles/Admin.css'; // Vous pouvez créer un fichier CSS correspondant pour styliser ce panneau

const Admin = () => {
    const [users, setUsers] = useState([]);
    const [error, setError] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    const token = localStorage.getItem('token');

    useEffect(() => {
        fetchUsers();
    }, );

    const fetchUsers = async () => {
        try {
            const response = await axios.get('http://192.162.70.175:4000/users', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setUsers(response.data);
        } catch (error) {
            setError('Erreur lors de la récupération des utilisateurs.');
        }
    };

    const updateUserRole = async (userId, newRole) => {
        try {
            await axios.put(`http://192.162.70.175:4000/users/${userId}/role`, { role: newRole }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setUsers((prevUsers) =>
                prevUsers.map((user) =>
                    user._id === userId ? { ...user, role: newRole } : user
                )
            );
        } catch (error) {
            setError("Erreur lors de la mise à jour du rôle de l'utilisateur.");
        }
    };

    const deleteUser = async (userId) => {
        try {
            await axios.delete(`http://192.162.70.175:4000/users/${userId}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setUsers((prevUsers) => prevUsers.filter((user) => user._id !== userId));
        } catch (error) {
            setError("Erreur lors de la suppression de l'utilisateur.");
        }
    };

    const filteredUsers = users.filter((user) =>
        user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="admin-panel">
            <h2>Panneau d'Administration</h2>
            {error && <p className="error">{error}</p>}
            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Rechercher un utilisateur..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            <table className="users-table">
                <thead>
                <tr>
                    <th>Nom</th>
                    <th>Email</th>
                    <th>Rôle</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {filteredUsers.map((user) => (
                    <tr key={user._id}>
                        <td>{user.firstName} {user.lastName}</td>
                        <td>{user.email}</td>
                        <td>{user.role}</td>
                        <td>
                            {user.role !== 'admin' ? (
                                <button className="assign-admin" onClick={() => updateUserRole(user._id, 'admin')}>
                                    Attribuer le rôle Admin
                                </button>
                            ) : (
                                <button className="remove-admin" onClick={() => updateUserRole(user._id, 'member')}>
                                    Retirer le rôle Admin
                                </button>
                            )}
                            <button className="edit-user" onClick={() => console.log('Modifier utilisateur', user._id)}>
                                Modifier
                            </button>
                            <button className="delete-user" onClick={() => deleteUser(user._id)}>
                                Supprimer
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default Admin;
