import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Linkify from 'react-linkify';
import { useParams } from 'react-router-dom';
import "../../styles/GroupeDetails.css";
import {jwtDecode} from "jwt-decode";

const GroupeDetails = () => {
    const { id } = useParams();
    const [groupe, setGroupe] = useState(null);
    const [documents, setDocuments] = useState([]);
    const [events, setEvents] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchEventTerm, setSearchEventTerm] = useState('');
    const [visibleDocumentsCount, setVisibleDocumentsCount] = useState(5);
    const token = localStorage.getItem('token');
    const [isMember, setIsMember] = useState(false);
    const [showMembers, setShowMembers] = useState(false);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                setUserId(decodedToken.id);
            } catch (error) {
                console.error('Erreur lors du décodage du token', error);
            }
        }
    }, [token]);

    useEffect(() => {
        if (userId && token) {
            fetchGroupeDetails().then(r => 0);
            fetchDocuments().then(r => 0);
            fetchEvents().then(r => 0);
        }
    }, [userId, id, token]);

    useEffect(() => {
        if (groupe && userId) {
            const isUserMember = groupe.members.some(member => member._id === userId);
            setIsMember(isUserMember);
        }
    }, [groupe, userId]);

    const fetchGroupeDetails = async () => {
        try {
            const response = await axios.get(`http://192.162.70.175:4000/groupes/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setGroupe(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des détails du groupe', error);
        }
    };

    const fetchDocuments = async () => {
        try {
            const response = await axios.get('http://192.162.70.175:4000/documents', {
                headers: { Authorization: `Bearer ${token}` },
            });
            const filteredDocuments = response.data.filter(doc => doc.groupId && doc.groupId.toString() === id);
            setDocuments(filteredDocuments);
        } catch (error) {
            console.error('Erreur lors de la récupération des documents', error);
        }
    };

    const fetchEvents = async () => {
        try {
            const response = await axios.get('http://192.162.70.175:4000/events', {
                headers: { Authorization: `Bearer ${token}` },
            });

            const today = new Date().setHours(0, 0, 0, 0);
            const filteredEvents = response.data
                .filter(event => {
                    const eventDate = new Date(event.date).setHours(0, 0, 0, 0);
                    return event.groupId?._id === id && (eventDate >= today || eventDate === today);
                })
                .sort((a, b) => new Date(a.date) - new Date(b.date));
            setEvents(filteredEvents.slice(0, 3));
        } catch (error) {
            console.error('Erreur lors de la récupération des événements', error);
        }
    };

    const filteredDocuments = documents.filter((doc) =>
        doc.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const visibleDocuments = filteredDocuments.slice(0, visibleDocumentsCount);

    const filteredEvents = events.filter((event) =>
        event.title.toLowerCase().includes(searchEventTerm.toLowerCase())
    );

    const joinGroup = async () => {
        try {
            await axios.post(`http://192.162.70.175:4000/groupes/${id}/join`, {}, {
                headers: { Authorization: `Bearer ${token}` },
            });

            setIsMember(true);
            setGroupe(prev => ({
                ...prev,
                members: [...prev.members, { _id: userId }],
            }));
        } catch (error) {
            console.error('Erreur lors de la tentative de rejoindre le groupe', error.response?.data || error);
            alert("Erreur lors de la tentative de rejoindre le groupe.");
        }
    };

    const leaveGroup = async () => {
        try {
            await axios.post(`http://192.162.70.175:4000/groupes/${id}/leave`, {}, {
                headers: { Authorization: `Bearer ${token}` },
            });

            setIsMember(false);
            setGroupe(prev => ({
                ...prev,
                members: prev.members.filter(member => member._id !== userId)
            }));
        } catch (error) {
            console.error('Erreur lors de la tentative de quitter le groupe', error.response?.data || error);
            alert("Erreur lors de la tentative de quitter le groupe.");
        }
    };

    const downloadDocument = async (filePath, fileName) => {
        try {
            const response = await axios.get(`http://192.162.70.175:4000/${filePath}`, {
                headers: { Authorization: `Bearer ${token}` },
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Erreur lors du téléchargement du document', error);
            alert("Erreur lors du téléchargement du document.");
        }
    };

    return (
        <div className="groupe-details">
            <h2>Détails du groupe de travail</h2>
            {groupe && (
                <div className="groupe-details-info">
                    <h3>{groupe.name}</h3>
                    <p>{groupe.description}</p>
                    <p>Membres : {groupe.members.length}</p>
                    {!isMember && (
                        <button onClick={joinGroup} className="join-group-button">
                            Rejoindre le groupe
                        </button>
                    )}
                    {isMember && (
                        <>
                            <button onClick={leaveGroup} className="leave-group-button">
                                Quitter le groupe
                            </button>
                        </>
                    )}
                    <button onClick={() => setShowMembers(!showMembers)} className="show-members-button">
                        {showMembers ? 'Masquer les membres' : 'Afficher les membres'}
                    </button>
                    {showMembers && (
                        <div className="members-list">
                            <h4>Liste des membres :</h4>
                            <ul>
                                {groupe.members.map((member) => (
                                    <li key={member._id}>
                                        {member.firstName} {member.lastName}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            )}
            <div className="groupe-documents">
                <h3>Documents du groupe</h3>
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="Rechercher un document..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                {filteredDocuments.length === 0 ? (
                    <p>Aucun document pour ce groupe.</p>
                ) : (
                    visibleDocuments.map((doc) => (
                        <div key={doc._id} className="document-item">
                            <h4 className="document-title">{doc.name}</h4>
                            <a
                                href={`http://192.162.70.175/:4000/${doc.filePath}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="document-link"
                            >
                                Lire le document
                            </a>
                            <button
                                onClick={() => downloadDocument(doc.filePath, doc.name)}
                                className="download-button"
                            >
                                Télécharger
                            </button>
                        </div>
                    ))
                )}
                {filteredDocuments.length > visibleDocumentsCount && (
                    <button
                        onClick={() => setVisibleDocumentsCount(visibleDocumentsCount + 5)}
                        className="show-more-button"
                    >
                        Afficher plus
                    </button>
                )}
                {visibleDocumentsCount > 5 && (
                    <button
                        onClick={() => setVisibleDocumentsCount(5)}
                        className="show-less-button"
                    >
                        Afficher moins
                    </button>
                )}
            </div>
            <div className="groupe-events recent-events">
                <h3>Événements récents du groupe</h3>
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="Rechercher un événement..."
                        value={searchEventTerm}
                        onChange={(e) => setSearchEventTerm(e.target.value)}
                    />
                </div>
                {filteredEvents.length === 0 ? (
                    <p>Aucun événement pour ce groupe.</p>
                ) : (
                    filteredEvents.map((event) => (
                        <div key={event._id} className="event-item">
                            <h4>{event.title}</h4>
                            <Linkify>
                                <p>Liens : {event.link}</p>
                            </Linkify>
                            <p> Message : {event.message}</p>
                            <p>Date : {new Date(event.date).toDateString()}</p>
                            <p>Heure de début : {event.startTime}</p>
                            <p>Heure de fin : {event.endTime}</p>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default GroupeDetails;
