import React from 'react';
import '../../styles/Mediation.css';

const Mediation = () => {
    return (
        <div className="mediation-container">
            <div className="left-images-section">
                <img src="/assets/mediation2.png" alt="Discussion" className="mediation-image"/>
                <img src="/assets/mediation4.png" alt="Discussion" className="mediation-image"/>
                <img src="/assets/mediation5.png" alt="Meeting" className="mediation-image"/>
            </div>
            <div className="right-text-section">
                <h2>Un métier aux profils divers</h2>
                <p className="mediation-text">
                    «Médiateur.rices en santé», «pair médiateur.rice», «agent de santé communautaire» ou encore
                    «navigateur.rice» les dénominations pour désigner les postes exerçant des missions de médiation en
                    santé sont nombreuses mais se rejoignent par une fonction commune: la médiation en santé.
                </p>
                <p className="mediation-text">
                    Il.elles peuvent être issu.es ou non de la population accompagnée. Dans le premier cas, on parle
                    alors de travailleur.euses pairs qui s’appuient sur leurs savoirs acquis au cours de leurs
                    expériences de vie.
                </p>
                <h2>Activités</h2>
                <p className="mediation-text">
                    Ce métier s’exerce à travers des actions en centre fixe (établissements ou associations des secteurs
                    sanitaire, social ou médico-social) et/ou des actions d’aller vers ainsi que de ramener vers le
                    système de santé.
                </p>
                <p className="mediation-text">
                    Les médiatrices et médiateurs en santé ne pratiquent pas de soins curatifs. Il.elles n’ont pas
                    vocation à se substituer aux autres professionel.les du sanitaire ou de l’intervention sociale qui
                    interviennent en complémentarité dans le parcours de santé des personnes.
                </p>
                <p className="mediation-text">
                    Les médiatrices et médiateurs en santé s’appuient sur leurs connaissances fines des besoins et
                    problématiques des populations, ainsi que des acteurs locaux au sein du territoire.
                </p>
                <h2>Un métier à reconaitre</h2>
                <p className="mediation-text">
                La médiation en santé est ainsi un métier pivot du système de santé pour garantir l’effectivité du
                    droit à la santé des personnes et lutter contre les inégalités sociales de santé. Partielle, la
                    reconnaissance institutionnelle de la médiation en santé est encore à achever.
                </p>
                <p className="mediation-text">
                    En effet, si la fonction est dotée d’un statut législatif (loi n°2016-41 et article D110-5 CSP) et
                    d’un cadre de référence qui structure son activité (référentiel de la HAS), le cadre d’emploi des
                    médiatrices et médiateurs en santé n’est pas encore officiellement reconnu par les pouvoirs publics.
                </p>
                <p className="mediation-text">
                    Le métier n’est toujours pas inscrit dans le Répertoire Opérationnel des Métiers et des Emplois
                    (ROME) ni dans le Code de la Santé Publique et il n’a pas de place opérationnelle définie dans le
                    système de santé.
                </p>
                <p className="mediation-text">
                    Les médiatrices et médiateurs en santé sont ainsi maintenu.es dans un mode d’exercice très précaire
                    : emploi mal rémunéré, absence de visibilité de carrière, rare intégration à une équipe
                    pluri-professionnelle, voire exercice isolé, budgets alloués non pérennes… Autant de freins qui
                    entravent l’efficacité et le déploiement des dispositifs de médiation en santé sur le territoire.
                </p>
            </div>
        </div>
    );
};

export default Mediation;
