import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/Documents.css';

const Documents = () => {
    const [documents, setDocuments] = useState([]);
    const [groups, setGroups] = useState([]); // État pour les groupes
    const [selectedFile, setSelectedFile] = useState(null);
    const [category, setCategory] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [currentDocument, setCurrentDocument] = useState(null);
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (token) {
            fetchDocuments();
            fetchGroups();
        } else {
            console.error('Token manquant, veuillez vous reconnecter.');
        }
    }, [token]);

    const fetchDocuments = async () => {
        try {
            const response = await axios.get('http://192.162.70.175:4000/documents', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setDocuments(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des documents', error);
        }
    };

    const fetchGroups = async () => {
        try {
            const response = await axios.get('http://192.162.70.175:4000/groupes', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setGroups(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des groupes', error);
        }
    };

    const handleFileUpload = async (e) => {
        e.preventDefault();
        if (!selectedFile) return;

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('category', category);

        const selectedGroup = groups.find(group => group.name === category);
        if (!selectedGroup) {
            console.error("Le groupe sélectionné n'est pas valide.");
            return;
        }
        formData.append('groupId', selectedGroup._id);

        try {
            const response = await axios.post('http://192.162.70.175:4000/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            });
            setDocuments([...documents, response.data]);
            setSelectedFile(null);
            setCategory('');
        } catch (error) {
            console.error('Erreur lors du téléchargement du document', error);
        }
    };


    const handleDeleteDocument = async (id) => {
        try {
            await axios.delete(`http://192.162.70.175:4000/documents/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setDocuments(documents.filter((doc) => doc._id !== id));
        } catch (error) {
            console.error('Erreur lors de la suppression du document', error);
        }
    };

    const handleEditDocument = (document) => {
        setIsEditing(true);
        setCurrentDocument(document);
        setCategory(document.category);
    };

    const handleUpdateDocument = async (e) => {
        e.preventDefault();
        if (!currentDocument) return;

        try {
            const response = await axios.put(
                `http://192.162.70.175:4000/documents/${currentDocument._id}`,
                {
                    name: currentDocument.name,
                    category,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setDocuments(
                documents.map((doc) => (doc._id === currentDocument._id ? response.data : doc))
            );
            setIsEditing(false);
            setCurrentDocument(null);
            setCategory('');
        } catch (error) {
            console.error('Erreur lors de la mise à jour du document', error);
        }
    };

    return (
        <div className="documents-section">
            <h2>Dépôt de Documents</h2>
            <form onSubmit={isEditing ? handleUpdateDocument : handleFileUpload} className="upload-form">
                {!isEditing && (
                    <input type="file" onChange={(e) => setSelectedFile(e.target.files[0])} required />
                )}
                <div className="form-group">
                    <label htmlFor="category">Catégorie :</label>
                    <select
                        id="category"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        required
                    >
                        <option value="">-- Sélectionnez un groupe --</option>
                        {groups.map((group) => (
                            <option key={group._id} value={group.name}>
                                {group.name}
                            </option>
                        ))}
                    </select>
                </div>
                <button type="submit">{isEditing ? 'Mettre à jour' : 'Déposer'}</button>
            </form>

            <div className="documents-list">
                <h3>Documents Déposés</h3>
                {documents.length === 0 ? (
                    <p>Aucun document déposé pour le moment.</p>
                ) : (
                    documents.map((document) => (
                        <div key={document._id} className="document-item">
                            <h4>{document.name}</h4>
                            <p>Catégorie : {document.category}</p>
                            <p>Date de dépôt : {document.uploadedAt}</p>
                            <a
                                href={`http://localhost:4000/${document.filePath}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Lire le document
                            </a>
                            <div className="document-actions">
                                <button onClick={() => handleEditDocument(document)}>Modifier</button>
                                <button onClick={() => handleDeleteDocument(document._id)}>Supprimer</button>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default Documents;
