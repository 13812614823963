import React from 'react';

const Image2 = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '16px', padding: '16px' }}>
      {/* Image 1 */}
      <div style={{ flex: '1 1 45%', border: '1px solid #ddd', padding: '16px' }}>
        <img
          src="/assets/3.png"
          alt="Description of Image 1"
          style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
        />
      </div>

      {/* Image 2 */}
      <div style={{ flex: '1 1 45%', border: '1px solid #ddd', padding: '16px' }}>
        <img
          src="/assets/4.png"
          alt="Description of Image 2"
          style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
        />
      </div>
    </div>
  );
};

export default Image2;
