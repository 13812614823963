import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../../styles/SubResources.css';

const SubResources = () => {
    const { category } = useParams();
    const [resources, setResources] = useState([]);
    const token = localStorage.getItem('token');
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (token) {
            fetchResources();
        }
    }, [category, token]);

    const fetchResources = async () => {
        try {
            const response = await axios.get(`http://192.162.70.175:4000/ressources/${category}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setResources(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des ressources', error);
        }
    };

    const deleteResource = async (id) => {
        try {
            await axios.delete(`http://192.162.70.175:4000/ressources/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // Mise à jour de la liste des ressources après suppression
            setResources(resources.filter(resource => resource._id !== id));
        } catch (error) {
            console.error('Erreur lors de la suppression de la ressource', error);
        }
    };

    const filteredResources = resources.filter((resource) =>
        resource.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="sub-resources">
            <h2>Ressources de la catégorie : {category.charAt(0).toUpperCase() + category.slice(1)}</h2>
            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Rechercher une ressource..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            {filteredResources.length === 0 ? (
                <p>Aucune ressource trouvée dans cette catégorie.</p>
            ) : (
                <ul className="resources-list">
                    {filteredResources.map((resource) => (
                        <li key={resource._id} className="resource-item">
                            <h3>{resource.name}</h3>
                            <a
                                href={`http://192.162.70.175:4000/${resource.filePath}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="resource-link"
                            >
                                Voir la ressource
                            </a>
                            <button
                                onClick={() => deleteResource(resource._id)}
                                className="delete-button"
                            >
                                Supprimer
                            </button>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SubResources;